<template>
  <div class="carrosel-wraper">
    <vueper-slides class="no-shadow" bullets-inside arrows-outside :dragging-distance="40" fixed-height="260px" transition-speed="500">
      <template #arrow-left>
        <i class="fas fa-chevron-left"></i>
      </template>

      <template #arrow-right>
        <i class="fas fa-chevron-right"></i>
      </template>

      <slot v-for="(slide, i) in displayFiles">
        <vueper-slide lazy lazy-load-on-drag v-if="isImage(slide.extension)" :key="i" :image="slide.file" />
        <vueper-slide v-if="isVideo(slide.extension)" :key="i" :video="formattedVideo(slide)" />
      </slot>
    </vueper-slides>
  </div>
</template>

<style lang="scss">
@import './Carousel.scss';
</style>

<script>
import { VueperSlide, VueperSlides } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  props: {
    displayFiles: [],
  },

  components: {
    VueperSlides,
    VueperSlide,
  },

  data() {
    return {
      slide: 0,
    };
  },

  methods: {
    isImage(extension) {
      return ['png', 'jpg', 'jpeg', 'svg', 'gif', 'webp'].includes(extension.toLowerCase());
    },
    isVideo(extension) {
      return ['mp4', 'avi', 'webm', 'mov', 'wmv', 'mkv', 'mpeg4', '3gp'].includes(extension.toLowerCase());
    },
    formattedVideo(videoObj) {
      return { [videoObj.extension]: videoObj.file, autoplay: false, controls: true };
    },
  },
};
</script>
